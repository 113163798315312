body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav-link {
    color: #000 !important;
    cursor: pointer !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    line-height: 27px !important;
    padding: 23px 10px 20px !important;
    position: relative !important;
}

.nav-link-active {
    color: #0048ff !important;
    cursor: pointer !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    line-height: 27px !important;
    position: relative !important;
}

@font-face {
    font-family: 'opendyslexic';
    src: local('opendyslexic3-regular'),
    local('opendyslexic3'),
    local('opendyslexic'),
    local('open-dyslexic'),
    url('assets/fonts/opendyslexic3-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
