.IncrementMouse {
    cursor: url("../../assets/images/cursor.png"), auto;
}

.Highlight {
    background-color: rgba(253, 215, 0, 0.5) !important;
}

.Link {
    background-color: rgba(253, 0, 30, 0.5) !important;
}

.TextSpacing {
    letter-spacing: 0.2em !important;
}

.DyslexiaFont {
    font-family: opendyslexic !important;
}

.Loading {
    cursor: progress;
}

.Paragraph1 {
    font-size: 16px !important;
}

.Paragraph2 {
    font-size: 19px !important;
}

.Paragraph3 {
    font-size: 22px !important;
}

.Paragraph4 {
    font-size: 25px !important;
}

.SmallParagraph1 {
    font-size: 11px !important;
}

.SmallParagraph2 {
    font-size: 14px !important;
}

.SmallParagraph3 {
    font-size: 17px !important;
}

.SmallParagraph4 {
    font-size: 20px !important;
}
