.Banner {
    background: rgb(5,5,138);
    background: linear-gradient(135deg, rgba(5,5,138,1) 45%, rgba(2,17,74,1) 100%);
    border-radius: 4vw;
}

.BannerHeader {
    color: #ffff00;
}

