/* Popup container - can be anything you want */
.popup {
    position: relative;
    display: inline-block;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* The actual popup */
.popup .popuptext {
    visibility: hidden;
    width: 160px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 10px;
    padding: 8px 0;
    margin-right: 10px;
    position: absolute;
    top: 10%;
    right: 100%;
    z-index: 1;
}

/* Popup arrow */
.popup .popuptext::after {
    content: "";
    position: absolute;
    top: 40%;
    left: 100%;
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent transparent #555;
}

/* Toggle this class - hide and show the popup */
.popup .show {
    visibility: visible;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
}

/* Toggle this class - hide and show the popup */
.popup .hide {
    visibility: hidden;
    -webkit-animation: fadeOut 1s;
    animation: fadeOut 1s;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeOut {
    from {opacity: 1;}
    to {opacity: 0;}
}

@keyframes fadeOut {
    from {opacity: 1;}
    to {opacity: 0;}
}

/* Popup container - can be anything you want */
.popup {
    position: relative;
    display: inline-block;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* The actual popup */
.popup .popuptext {
    visibility: hidden;
    width: 160px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 10px;
    padding: 8px 0;
    margin-right: 10px;
    position: absolute;
    top: 10%;
    right: 100%;
    z-index: 1;
}

/* Popup arrow */
.popup .popuptext::after {
    content: "";
    position: absolute;
    top: 40%;
    left: 100%;
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent transparent #555;
}

/* Toggle this class - hide and show the popup */
.popup .show {
    visibility: visible;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity:1 ;}
}

/*.mh-75 {*/
/*    min-height: 85px;*/
/*    position: relative;*/
/*}*/

/*.mh-75:hover{*/
/*    cursor:pointer;*/
/*}*/

/*.item-text {*/
/*    position:absolute;*/
/*    bottom:5px;*/
/*    width:100%;*/
/*    font-size:12px;*/
/*}*/

/*.bg-green{*/
/*    background: #6E9036 !important;*/
/*    color:white;*/
/*    fill: white !important;*/
/*}*/

/*.my-2{*/
/*    margin-top:0px !important;*/
/*    margin-bottom: 0px !important;*/
/*}*/