h1, h2, h3, h4, h5, h6 {
    color: #024E84 !important;
}

a {
    cursor: pointer;
}

p, label {
    color: black !important;
}

button[type="submit"] {
    background: #006eac !important;
    border: 1px solid #006eac !important;
    color: white !important;
}

button[type="submit"]:hover {
    background: #026399 !important;
    border: 1px solid #01517e !important;
}

.btn-primary {
    background: #006eac !important;
}

.options-card-header {
    background: #006eac !important;
}

.options-card-header > h3 {
    color: white !important;
}

#reCaptcha.border-danger iframe[title="reCAPTCHA"] {
    border: 1px solid #dc3545;
    border-radius: 0.25rem;
}

#options-button {
    width: 50px;
    height: 50px;
    animation-name: options-animation;
    -webkit-animation: options-animation;
    animation-duration: 3s;
    -webkit-animation-duration: 3s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}

#options-button:hover {
    width: 55px;
    height: 55px;
    animation-play-state: paused;
    /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
}

.MuiModal-root {
    background: transparent !important;
}

/*#main-banner {*/
/*    background: #eaf6f7 !important;*/
/*}*/

body {
    background: url("../assets/images/background.png") top center no-repeat;
    background-size: cover;
}

.accessibility-item:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.card-overflow::-webkit-scrollbar {
    width: 6px;
}

.card-overflow::-webkit-scrollbar-track {
    background: #f1f1f1;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    /*-webkit-border-radius: 10px;*/
    /*border-radius: 10px;*/
}

.card-overflow::-webkit-scrollbar-thumb {
    background: #888;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);*/
}

.card-overflow::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.owl-carousel-item {
    background: white;
}

.partners-img {
    vertical-align: middle;
    max-height: 180px;
}

.partners-item {
    max-height: 200px;
}

.owl-carousel-item:hover {
    border: 1px solid #b3b1b1 !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
}

.modal-size {
    position: fixed;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
}

.icon-spin {
    -webkit-animation: icon-spin 2s infinite linear;
    animation: icon-spin 2s infinite linear;
}

.btn-language:focus:not(:focus-visible) {
    outline-color: initial !important;
    outline-style: initial !important;
    outline-width: 0 !important;
    border: 0;
}

.btn-language:before, :after, :focus {
    outline-color: initial !important;
    outline-style: initial !important;
    outline-width: 0 !important;
    border: 0 !important;
}

.btn-language:focus + .btn, .btn:focus {
    outline: 0 !important;
    box-shadow: none !important;
    border: 0 !important;
}

.btn-language {
    border: 0 !important;
}
.card {
    background-color: rgba(255,255,255,0.75);
}


@media screen and (max-width: 699px){
    .main-banner-item:not(:nth-child(-n+2)) {
        display: none;
    }

    .main-banner-item:last-child {
        display: inline;
    }

    .modal-size {
        width: 100% !important;
    }
}

@media screen and (min-width: 700px) and (max-width: 1000px){
    .main-banner-item:nth-last-child(-n+6) {
        display: none;
    }

    .main-banner-item:last-child {
        display: inline;
    }
}


@media screen and (min-width: 700px){
    .modal-size {
        width: 700px !important;
        margin: 0 auto;
    }
}

@-webkit-keyframes icon-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes icon-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes options-animation {
    0%   {color: #006eac;}
    50% {color: #2a8fdd;}
    100%   {color: #006eac;}
}

/* Add animation (fade in the popup) */
@-webkit-keyframes options-animation {
    0%   {color: #006eac;}
    50% {color: #2a8fdd;}
    100%   {color: #006eac;}
}