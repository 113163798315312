.Frame {
    position: relative;
    height: 160px;      /* equals max image height */
    width: 160px;
    white-space: nowrap;
    margin: auto;
    horiz-align: center;
    vertical-align: middle;
}

.Helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.Img {
    position: absolute;
    vertical-align: middle;
    top: 0;
    bottom: 0;
    margin: auto;
    padding: 15px;
    /*max-height: 120px;*/
    /*max-width: 160px;*/
}
